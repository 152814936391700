












import {
	Component,
	Prop,
	Vue
} from "vue-property-decorator";
import vFreeApply from '@/components/v-free-apply.vue';
import casesData from '@/assets/data/pages2/cases'

@Component({
	components: {
		vFreeApply
	}
})
export default class extends Vue {
	private casesData = casesData.casesList

}
