export default {
	casesList: [
		{
			title: '美业（厦门）',
			desc: '“黑谷做事的整个风格，很像我在国外的那些大企业，非常的有责任心。我觉得与其自己去摸索去碰壁，或者去投很多的冤枉钱，你不如实实在在的找一个，已经有很多很多成功案例的公司去合作。”——厦门张总',
			imgUrl: require('../../images/pages2/case/img_01.jpg'),
		},
		{
			title: '美业（湖南）',
			desc: '“跟黑谷合作后，真正实现了简单我的生意。不需要像以前一样每个门店去看，你想想三家门店，你每家去看，这一天就完了。”——湖南何总',
			imgUrl: require('../../images/pages2/case/img_02.png'),
		},
		{
			title: '汽修（云南）',
			desc: '“跟黑谷合作1年比我过去9年干的还要多。共享这4年，给我的帮助是无法用价值去衡量的，他们是不离不弃，不断的在更新我们的思维，不管是什么节日，都会联系我们，给我们全国做的优秀的方案。”——云南温总',
			imgUrl: require('../../images/pages2/case/img_03.png'),
		},
		{
			title: '汽修（振新）',
			desc: '“黑谷共享模式的出现，真真正正解决了企业的最大问题，流量问题和存量问题。我认为我们企业的改变是颠覆的，彻底的。”——振兴名车汇',
			imgUrl: require('../../images/pages2/case/img_04.jpg'),
		},
		{
			title: '餐饮（亳州）',
			desc: '“他们（黑谷）的服务做成什么样，找你了，你不解决，他要盯着你解决，你不把这件事情做到底，它会盯着你把这件事做到底。”——亳州刘总',
			imgUrl: require('../../images/pages2/case/img_05.jpg'),
		},
		{
			title: '餐饮（重庆）',
			desc: '“共享店铺让老顾客的关系更密切了，进店消费频率也更高。不管是员工还是共享合作者，关系都很融洽，而且共享合作者给门店带来的客源是非常精准的。”——重庆刘总',
			imgUrl: require('../../images/pages2/case/img_06.jpg'),
		},
	]
  
}